import React from 'react';
import { chain } from 'lodash';
import * as d3 from 'd3';
import styled from 'styled-components';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

const Text = styled.text`
  font-size: ${rem(1)};
  font-weight: bold;
`;

const ValuesGraph = ({
  values,
  width,
}) => {
  if (width === 0) {
    return null;
  }
  const {
    percentile: percentiles,
    conversion: conversions,
  } = values;

  const margin = {
    left: rem(9),
    right: rem(9),
    top: rem(1),
    bottom: rem(1),
  };
  const barHeight = rem(1.8); //bar height has to be at least 2rem to make room for the padding
  const barPadding = rem(0.18);
  const height = conversions.length * (barHeight + barPadding) + margin.top + margin.bottom;
  const delay = 0;
  const duration = 1000;

  const combind = chain(Array(conversions.length))
    .map((sc, i) => ({
      ...conversions[i],
      norm: undefined,
      scaleType: undefined,
      rawScore: parseInt(conversions[i].rawScore, 10),
      conversion: conversions[i].norm,
      percentile: percentiles[i].norm,
    }))
    .sortBy((a) => (-Math.abs(a.conversion)))
    .map((sc, i) => ({
      ...sc,
      sorted: i,
    }))
    .sortBy((a) => (a.ranking))
    .value();

  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right ]);

  const y = d3
    .scaleBand()
    .domain(conversions.map((con) => (con.name)))
    .range([margin.bottom, height - margin.top])
    .round(false)
    .padding(barPadding / rem(1));

  const bottomAxis = d3.axisBottom(x);

  const G = combind.map((c) => {
    const yPos = y(c.name);
    let start = x(50);
    let w = x(c.percentile) - start;
    if (c.conversion <= 0) {
      start = x(c.percentile);
      w = x(50) - start;
    }
    return (
      <g
        key={`valuesBar_${c.id}`}
      >
        <Text
          x={x(0)}
          y={yPos}
          dy={y.step()}
          fill={Colors.grey}
          ref={(node) => {
            if (node) {
              const bbox = node.getBBox();
              d3
                .select(node)
                .attr(
                  'transform',
                  `translate(-${bbox.width + rem(0.25)}, -${bbox.height / 2})`,
                );
            }
          }}
          transform={`translate(-${margin.left}, 0)`}
        >
          {c.left.toUpperCase()}
        </Text>
        <rect
          ref={(node) => {
            d3
              .select(node)
              .attr('width', 0)
              .transition()
              .delay(delay)
              .ease(d3.easeLinear)
              .duration(duration)
              .attr('x', start)
              .attr('width', w);
          }}
          x={x(50)}
          y={yPos}
          width={0}
          height={y.bandwidth()}
          fill={c.sorted < 3 ? Colors.red : Colors.lightGrey}
        />
        <text
          y={yPos}
          dy={y.step()}
          ref={(node) => {
            // no node, no text
            if (!node) {
              return;
            }
            if (c.sorted > 2) {
              d3.select(node).attr('opacity', 0);
              return;
            }
            // print the text to the left or right of the bar
            const bbox = node.getBBox();
            let endpos = x(c.percentile);
            if (c.conversion < 0) {
              endpos = x(c.percentile) - bbox.width;
            }
            // make sure the text is not outside the graph
            if (endpos <= x(0)) {
              endpos = x(0);
            }
            if (endpos >= x(100) - bbox.width) {
              endpos = x(100) - bbox.width;
            }
            // position and fade in the text
            d3
              .select(node)
              .attr('x', endpos)
              .attr('transform', `translate(0, -${bbox.height / 2})`)
              .attr('opacity', 0)
              .transition()
              .delay(delay + duration)
              .duration(duration)
              .ease(d3.easeLinear)
              .attr('opacity', 1);
          }}
        >
          {c.percentile < 50 ? 100 - c.percentile : c.percentile}%
        </text>
        <Text
          x={x(100)}
          y={yPos}
          dy={y.step()}
          transform="translate(10, 0)"
          fill={Colors.grey}
          ref={(node) => {
            if (node) {
              const bbox = node.getBBox();
              d3
                .select(node)
                .attr('transform', `translate(0, -${bbox.height / 2})`);
            }
          }}
        >
          {c.right.toUpperCase()}
        </Text>
      </g>
    );
  });

  const CentralAxis = (
    <g>
      <line
        stroke={Colors.grey}
        strokeWidth={1}
        x1={x(50)}
        x2={x(50)}
        y1={y(0)}
        y2={height - margin.top - margin.bottom}
        transform={`translate(0, ${margin.top})`}
      />
    </g>
  );

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      {CentralAxis}
      {G}
      <g
        ref={(node) => d3.select(node).call(bottomAxis)}
        transform={`translate(0, ${y(conversions[conversions.length - 1].name) + barHeight})`}
      />
    </svg>
  );
};

export default ValuesGraph;
