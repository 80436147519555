import React from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

//line wrapping function
//taken from https://gist.github.com/mbostock/7555321
function wrap(txt, width) {
  txt.attr('font-size', '.8rem');
  txt.attr('font-weight', 'bold');
  txt.each(function () {
    const text = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    const lineHeight = 1.1; // ems
    const y = text.attr("y");
    const dy = parseFloat(text.attr("dy"));
    let word;
    let line = [];
    let lineNumber = 0;
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", 0)
      .attr("y", y)
      .attr("dy", `${dy}em`);
    // eslint-disable-next-line no-cond-assign
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        lineNumber += 1;
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", `${lineNumber * lineHeight + dy}em`)
          .text(word);
      }
    }
  });
}

// TODO: Make this load from the server
const percentiles = ['0', '2', '8', '17', '32', '50', '68', '83', '92', '98'];

const AbilitiesGraph = ({
  abilities,
  width: inWidth,
}) => {
  const {
    ability,
  } = abilities;

  let width = inWidth;
  if (inWidth === 0) {
    width = 500;
  }

  const margin = {
    left: rem(3),
    right: rem(3),
    top: rem(1),
    bottom: rem(4),
  };
  const barHeight = rem(20);
  const barPadding = 0.15;
  const height = barHeight + margin.top + margin.bottom;
  const delay = 0;
  const duration = 1000;

  const x = d3
    .scaleBand()
    .domain(ability.map((s) => (s.name)))
    .rangeRound([margin.left, width - margin.right])
    .padding(barPadding);

  const y = d3
    .scaleLinear()
    .domain([0, 9])
    .range([barHeight, margin.top]);

  const yAxisLeft = d3.axisLeft(y);
  const yAxisRight = d3.axisRight(y).tickFormat((d) => (percentiles[d])).tickPadding(rem(0.45));
  const bottomAxis = d3.axisBottom(x);
  const G = ability.map((c) => (
    <g
      key={`graph_${c.ranking}`}
    >
      <rect
        ref={(node) => {
          d3
            .select(node)
            .transition()
            .delay(delay)
            .ease(d3.easeLinear)
            .duration(duration)
            .attr('y', y(c.norm))
            .attr('height', barHeight - y(c.norm));
        }}
        x={x(c.name)}
        y={y(0)}
        width={x.bandwidth()}
        height={0}
        fill={Colors.green}
      />
      <text
        ref={(node) => {
          if (!node) {
            return;
          }
          const bbox = node.getBBox();
          d3
            .select(node)
            .attr('font-size', '1rem')
            .attr('fill', Colors.white)
            .attr('x', x(c.name) + x.bandwidth() / 2 - bbox.width / 2)
            .transition()
            .delay(delay)
            .duration(duration)
            .attr(
              'opacity',
              1,
            );
        }}
        opacity="0"
        y={y(c.norm) + rem(1.25)}
      >
        {c.norm}
      </text>
    </g>
  ));

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        ref={(node) => d3.select(node).call(yAxisLeft)}
        transform={`translate(${margin.left}, 0)`}
      />
      {G}
      <g
        ref={(node) => d3.select(node).call(yAxisRight)}
        transform={`translate(${width - margin.right}, 0)`}
      />
      <g
        ref={
          (node) => {
            d3
              .select(node)
              .call(bottomAxis)
              .selectAll(".tick text")
              .attr("transform", `translate(-${rem(1)}, ${rem(1.5)}) rotate(-45)`)
              .call(wrap, x.bandwidth());
          }
        }
        transform={`translate(0, ${barHeight})`}
      />
    </svg>
  );
};

export default AbilitiesGraph;
